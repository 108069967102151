import React from 'react';
// import { globalHistory } from "@reach/router";

import { ForgotPasswordForm } from '../components/form';
import { RouteComponentProps } from '@reach/router';

import SEO from '../components/shared/seo';

const PasswordRecoveryPage = (props: RouteComponentProps) => {
    const { location } = props;
    // console.log('GLOBAL HISTORY: ', JSON.stringify(globalHistory, null, 2))

    return (
        <>
            <SEO title='Password Recovery' />
            {
                location && location.state ? (
                    <ForgotPasswordForm locationState={location.state} />
                ) : (
                    typeof window !== 'undefined' && window.location.replace('/landing')
                )
            }
        </>
    )
};

export default PasswordRecoveryPage;
