import React, { useContext, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { FormattedMessage } from '../../shared'
import { IntlContext } from '../../../state'

import { Form, Typography, Divider, Button, Input, Row, Col } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { NewPasswordPolicyHelp } from '../register/new-password-policy-help'
import { confirmForgotPassword } from '../../../utils/auth/auth-service'
import { showNotification } from '../../../utils/extras/notification.helpers'

import '../../shared/global.styles.scss'

const { Title, Text } = Typography

interface ForgotPasswordProps extends RouteComponentProps {
  locationState: any;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordProps> = (props) => {
  const { locationState } = props; 
  const { formatMessage } = useContext(IntlContext)
  const [form] = Form.useForm()

  // COMING FROM HISTORY
  const usernamePassed = typeof locationState !== 'undefined' ? locationState.username : ''
  const aliasReceiver = typeof locationState !== 'undefined' ? locationState.aliasReceiver : ''
  const deliveryMedium = typeof locationState !== 'undefined' ? locationState.deliveryMedium : ''

  // LOCAL STATE
  const [newPassword, setNewPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleFinish = (values: any) => {
    setLoading(true)
    // TODO: use intl
    if (values && usernamePassed) {
      confirmForgotPassword(usernamePassed, values.code, values.newPassword)
        .then(result => {
          if (typeof result === 'object') {
            showNotification(
              'success',
              formatMessage({
                id: 'app.account.change-password.notification.title',
              }),
              formatMessage({
                id: 'app.account.change-password.notification.description',
              }),
            )
            navigate('/landing')
          } else if (result === 'Code Mistmatch') {
            form.setFields([
              {
                name: 'code',
                errors: [result],
              },
            ])
            setLoading(false)
          } else if (result === 'Code Expired') {
            form.setFields([
              {
                name: 'code',
                errors: [result],
              },
            ])
            setLoading(false)
          } else {
            showNotification('error', 'Oops!', result)
            setLoading(false)
          }
        })
        .catch(err => {
          console.error('Error throwed on forgot password', err)
          showNotification(
            'error',
            'Oops!',
            'Something went wrong during forgot password process.',
          )
          setLoading(false)
        })
    }
  }

  const validatePasswordPolicies = (_rule: unknown, value: string) => {
    // TODO: extract this value to an application-level constant
    const passwordPolicy = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

    if (!value) {
      return Promise.reject('Please, enter a new password!')
    } else if (!passwordPolicy.test(value)) {
      return Promise.reject(
        formatMessage({ id: 'app.account.change-password.policies' }),
      )
    } else {
      return Promise.resolve()
    }
  }

  const onNewPasswordChage = (changedValue: any, allValues: any) => {
    if (changedValue.newPassword) {
      setNewPassword(allValues.newPassword)
    }
  }

  return (
    <Row justify="center" align="middle" className="forgot-password-row">
      <Col className="forgot-password-col"
        xl={{ span: 8 }}
        lg={{ span: 10 }}
        md={{ span: 12 }}
      >
        <Title level={4} className="container-title">
          Create New Password
        </Title>

        <Divider />

        <div>
          <Text>
            {`We have sent a password reset code by ${deliveryMedium} to `}
            <Text strong>{aliasReceiver}</Text>
            {`. Enter it below to reset your password.`}
          </Text>
        </div>

        {/* FORM */}
        <Form
          form={form}
          style={{ marginTop: '1rem' }}
          layout="horizontal"
          onFinish={handleFinish}
          onValuesChange={onNewPasswordChage}
        >
          {/* VERIFICATION CODE */}
          {/* TODO: ask for username verification constrains */}
          <Form.Item
            name="code"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please enter the verification code!',
              },
              {
                pattern: /^[0-9]{6}$/g,
                message: 'The verification code MUST contain 6 digits!',
              }
            ]}
          >
            <Input placeholder="Verification Code" />
          </Form.Item>

          {/* NEW PASSWORD */}
          <Form.Item
            name="newPassword"
            rules={[
              // { required: true, message: 'Please enter your password.' },
              { validator: validatePasswordPolicies },
            ]}
          >
            <Input.Password
              placeholder="New Password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>

          {/* CONFIRM PASSWORD */}
          <Form.Item
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: 'Please confirm your new password.' },
              // { validator: compareToFirstPassword },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('The passwords do not match!')
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>

          {/* PASSWORD POLICIES HELP */}
          <NewPasswordPolicyHelp newPassword={newPassword} />

          {/* <Divider /> */}
          <Divider className={'container-divider-for-error'} />

          {/* CHANGE PASSWORD BUTTON */}
          <Button
            loading={loading}
            className="user-entry-form-submit-button"
            htmlType="submit"
          >
            Change Password
          </Button>
        </Form>
      </Col>
    </Row>
  )
}
