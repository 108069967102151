import React, { useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { useDispatch } from 'react-redux'
import { setUser } from '../../../state/user/actions/set-user.action'

import {
  confirmSignUp,
  login,
  resendConfirmationCode,
} from '../../../utils/auth/auth-service'
import { showNotification } from '../../../utils/extras/notification.helpers'

import { Typography, Divider, Form, Button, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import PinInput from 'react-pin-input'

import '../../shared/global.styles.scss'

const { Title, Text } = Typography

interface VerificationPasswordProps extends RouteComponentProps {
  locationState: any;
}

export const VerificationCodeForm: React.FC<VerificationPasswordProps> = (props) => {
  const { locationState } = props;
  const dispatch = useDispatch()

  // LOCAL STATE
  const [loading, setLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState(' ')
  // COMING FROM HISTORY STATE
  const msgHelpForNotVerifiedUser = typeof locationState !== 'undefined' ? locationState.message : ''
  const aliasReceiver = typeof locationState !== 'undefined' ? locationState.aliasReceiver : ''
  const username = typeof locationState !== 'undefined' ? locationState.username : ''
  const password = typeof locationState !== 'undefined' ? locationState.password : ''
  const comingFrom = typeof locationState !== 'undefined' ? locationState.comingFrom : ''
  let code: PinInput | null

  // HANDLE VERIFICATION CODE ON COMPLETE EVENT
  const handleOnCompletedVerificationCode = (value: string) => {
    setLoading(true)

    // Coming from the registration
    if (username && password) {
      confirmSignUp(username, value)
        .then(result => {
          if (typeof result === 'object') {
            // Perform Login if verification is successfull
            login(username, password).then(result => {
              if (typeof result === 'object') {
                dispatch(setUser(result))
                navigate('/')
                showNotification(
                  'success',
                  `${comingFrom ? 'Account Created!' : 'Account Verified!'}`,
                  `${comingFrom
                      ? 'Your account has been created successfully.'
                      : 'Your account has been verified successfully.'
                  }`,
                )
              } else {
                showNotification(
                  'error',
                  'Unexpected Error!',
                  'Something went wrong confirming your account.',
                )
              }
            })
          } else {
            if (result === "Code Mistmatch") {
              setErrorMessage("Oops, this code doesn't match the code we sent. Please try again.")
            } else {
              setErrorMessage(result)
            }
            setShowErrors(true)
          }
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          // TODO: show notification if error during register confirmation
          showNotification(
            'error',
            'Unexpected Error!',
            'Something went wrong during sign up process.',
          )
        })
    }
  }

  // HANDLE RESET CODE ACTION
  const handleResendCode = () => {
    setResendLoading(true)
    setShowErrors(false)
    setErrorMessage('')
    code?.clear()

    if (username) {
      resendConfirmationCode(username)
        .then(result => {
          // createLog(JSON.stringify(result, null, 4));
          showNotification(
            'success',
            'Code Sent!',
            `A new confirmation code has been sent to ${result.CodeDeliveryDetails.Destination}.`,
          )
          setResendLoading(false)
        })
        .catch(err => {
          showNotification('error', 'Oops!', err as string)
          setResendLoading(false)
        })
    }
  }

  return (
    <Row justify="center">
      <Col
        className="verification-code-col"
        xl={{ span: 10 }}
        lg={{ span: 12 }}
        md={{ span: 12 }}
      >
        <Title level={4} className="container-title">
          Verification Code
        </Title>

        <Divider />

        <div>
          {/* TODO: use intl */}
          <Text>{msgHelpForNotVerifiedUser}</Text>
          <Text>A temporary code has been sent to</Text>
          <Text strong>{` ${aliasReceiver}`}</Text>
          <Text>. Please enter the code to confirm your account.</Text>
        </div>
        <Form style={{ marginTop: '1rem' }}>
          {/* VERIFICATION CODE FIELD */}
          <Form.Item>
            <div style={{ display: 'flex' }}>
              <PinInput
                length={6}
                focus
                ref={c => (code = c)}
                initialValue=""
                type="numeric"
                onComplete={handleOnCompletedVerificationCode}
              />
              {loading && <LoadingOutlined className="loading-icon" />}
            </div>
            {showErrors && (
              <div className="code-error-container">
                <Text type="danger">{errorMessage}</Text>
              </div>
            )}
          </Form.Item>

          {/* RESENT CODE BUTTON */}
          <Divider
            style={
              showErrors ? { marginTop: 'auto' } : { marginTop: '1.7rem' }
            }
          />
          <Button
            loading={resendLoading}
            className="verification-submit-button"
            onClick={handleResendCode}
          >
            Resend Code
          </Button>
        </Form>
      </Col>
    </Row>
  )
}
